import Vue from 'vue'
import App from './App.vue'

import vuetify from '@/plugins/vuetify'
import '@/plugins/activity'

import '@/scss/index.scss';

import '@axios'
import '@/libs/vue2-storage'
import '@/libs/vue-uuid'
import '@/libs/vue-select'
import '@/libs/vue-moment'
import '@/libs/vue-recaptcha-v3'

import router from './router'
import store from './store'


Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
