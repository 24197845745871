import request from '@axios';

export function getUploadUrl(params) {
  return request({
    method: 'get',
    url: '/upload-url',
    params: params
  })
}

export function uploadFile(url, blob) {
  return request({
    method: 'put',
    url: url,
    data: blob,
    headers: {
      'Content-Type': blob.type,
      'Access-Control-Allow-Origin': '*'
    }
  })
}
