import request from '@axios'

export function getQuestionnaire(version) {
  return request({
    url: '/questionnaire',
    method: 'get',
    params: {version},
  })
}

