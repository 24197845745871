import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({

  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#F92F6B',
        error: '#C7003B'
      },
    },
  },
});
