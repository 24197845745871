import Vue from 'vue'
import VueSelect from 'vue-select'
import {VIcon} from "vuetify/lib/components";

VueSelect.props.components.default = () => ({

  OpenIndicator: {
    render: h => h(VIcon, {
        props: {color: 'primary'},
        domProps: {
          innerHTML: 'mdi-arrow-up'
        },
      }
    ),
  },
})

VueSelect.props.label.default = 'text'
VueSelect.props.clearable.default = false

Vue.component(VueSelect)
