import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import {createRespondent} from "@/api/respondent";
import {getQuestionnaire} from "@/api/questionnaire";
import {getUploadUrl, uploadFile} from "@/api/record";
import {saveAnswer} from "@/api/answer";
import moment from "moment";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    uuid: null,
    version: null,
    answers: [],
    questionnaire: null,
    currentQuestionBlockId: null
  },
  getters: {
    questionnaireProgress: state => {
      if (!state.questionnaire) {
        return 0
      }

      return Math.ceil((state.currentQuestionBlockId / state.questionnaire.length) * 100);
    }
  },
  actions: {
    async init({commit, state}, data) {
      const response = await createRespondent(data);

      commit('SET_UUID', data.uuid)
      commit('SET_VERSION', response.data.version)
      commit('SET_CURRENT_QUESTION_BLOCK_ID', 0)
    },
    finish({commit, dispatch}) {
      dispatch('clear')
    },
    clear({commit}) {
      commit('SET_UUID', null)
      commit('SET_VERSION', null)
      commit('SET_CURRENT_QUESTION_BLOCK_ID', null)
      commit('SET_QUESTIONNAIRE', null)
    },

    async loadQuestionnaire({commit, state}) {
      // return commit('SET_QUESTIONNAIRE', require('../../demo-data.json'))

      const response = await getQuestionnaire(state.version);
      commit('SET_QUESTIONNAIRE', response.data.questionnaire)
    },

    async saveAnswers({commit, state}, questionBlock) {

      const answer = {
        questionBlock: questionBlock,
        uuid: state.uuid,
        version: state.version,
        createdAt: moment().format('YYYY-MM-DD HH:mm:ss Z')
      }

      if (questionBlock.type === 'AudioRecording') {

        try {
          const response = await getUploadUrl({
            type: questionBlock.blob.type,
            uuid: state.uuid,
            key: questionBlock.id
          });

          await uploadFile(response.data.uploadURL, questionBlock.blob);

          answer.questionBlock = {
            id: questionBlock.id,
            type: questionBlock.type,
            value: response.data.uploadURL.split('?')[0]
          }

          await saveAnswer(answer);

        } catch (error) {
          console.log(error)
        }
      }

      if (questionBlock.type === 'Questions') {
        await saveAnswer(answer);
      }
    }
  },
  mutations: {
    SET_UUID(state, uuid) {
      state.uuid = uuid;
    },
    SET_VERSION(state, version) {
      state.version = version;
    },
    SET_QUESTIONNAIRE(state, questionnaire) {
      state.questionnaire = questionnaire;
    },
    SET_CURRENT_QUESTION_BLOCK_ID(state, id) {
      state.currentQuestionBlockId = id;
    },
  },
  plugins: [
    createPersistedState({
      paths: [
        'uuid',
        'version',
        'currentQuestionBlockId',
      ]
    })
  ]
})
