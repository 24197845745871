import {trackEvent} from '@/api/activity';
import store from '@/store';

import moment from "moment";

const track = (data) => {
  const now = moment().format('YYYY-MM-DD HH:mm:ss Z')

  trackEvent({
    respondentUUID: store.state.uuid,
    questionnaireVersion: store.state.version,
    questionBlockId: store.state.currentQuestionBlockId,
    createdAt: now,
    ...data,
  })
}

export default () => {
  const trackClick =  (name, value, questionId) => {

    const questionTitle = store.state.questionnaire[ store.state.currentQuestionBlockId].title

    track({
      type: 'click',
      name: `${questionTitle}:${name}`,
      value: value,
      questionId: questionId,
    })
  };
  const trackRecordingInfo =  () => {

    track({
      type: 'click',
      name: 'Audio Recording Information:Next',
    })
  };

  const trackView = (vm, name) => {
    let time = 0;

    const interval = setInterval(() => {
      time += 1;
    }, 1000)

    vm.$once('hook:destroyed', () => {
      track({
        type: 'view',
        name: name,
        value: time
      })

      clearInterval(interval)
    })
  }

  return {
    trackClick,
    trackView,
    trackRecordingInfo,
  }
}
