import {trackEvent} from "@/api/activity";
import store from '@/store';
import moment from "moment";

function bindEvent(el, {arg, value}, vnode) {
  el._trackHandler = e => {
console.log(el, {arg, value}, vnode)
    const activityValue = el.getAttribute('activity-value')
    const activityQuestionId = el.getAttribute('activity-question-id')
    const now = moment().format('YYYY-MM-DD HH:mm:ss Z');
    const questionTitle = store.state.questionnaire[store.state.currentQuestionBlockId].title

    if (value === 'audio_recording_information') {
      return trackEvent({
        type: 'click',
        name: 'Audio Recording Information:Next',
        respondentUUID: store.state.uuid,
        questionnaireVersion: store.state.version,
        createdAt: now,
      });
    }

    trackEvent({
      type: 'click',
      name: `${questionTitle}:${value}`,
      respondentUUID: store.state.uuid,
      questionnaireVersion: store.state.version,
      questionBlockId: store.state.currentQuestionBlockId,
      createdAt: now,
      ...(activityValue !== null ? {value: activityValue} : {}),
      ...(activityQuestionId !== null ? {questionId: activityQuestionId} : {}),
    });
  }
  el.onclick = el._trackHandler
}

function unbindEvent(el) {
  delete el.onclick
}


export default {
  bind(el, binding, vnode) {
    bindEvent(el, binding, vnode)
  },
  update(el, binding, vnode) {
    unbindEvent(el)
    bindEvent(el, binding, vnode)
  },
  unbind: unbindEvent
}
