import Vue from 'vue'
import setupTracking from "./tracking";
import directive from "./directive";

const plugin =  {
  install (Vue, options) {

    const {trackClick, trackView, trackRecordingInfo} = setupTracking()

    Vue.prototype.$activity = {
      trackView,
      trackClick,
      trackRecordingInfo
    }

    Vue.directive('activity', directive)
  }
}


Vue.use(plugin)


